import React from "react";
import CarousalCard from "./CarousalCard";
import "./Style.scss";
import arrow from "../../images/blogimages/carousal-Btn.png";
import { useState } from "react";


const Carousal = ({ data }) => {
  let filterSelected = data.allWpPost.nodes.filter((blog) => {
    return blog.acf.showInCarousal === true;
  });

  const [itemNumber, setItemNumber] = useState(0);

  let moveCarousalLeft = () => {
    if (itemNumber === 0) {
      setItemNumber(filterSelected.length - 1);

      return;
    }

    setItemNumber(itemNumber - 1);
  };

  let moveCarousalRight = () => {
    if (itemNumber === filterSelected.length - 1) {
      setItemNumber(0);

      return;
    }

    setItemNumber(itemNumber + 1);
  };

  // useEffect(() =>{
  //   let a = setInterval(() => {
  //     moveCarousalRight();
  //   },5000)

  //   return(() => clearInterval(a));
  // })

  let edge = filterSelected[itemNumber];

  return (
    <div className="carousal">
      <div className="btn-holder">
        <button>
          <img src={arrow} className="arrow" onClick={moveCarousalLeft} />{" "}
        </button>
        <button>
          <img
            src={arrow}
            className="arrow right"
            onClick={moveCarousalRight}
          />{" "}
        </button>
      </div>
      {
        <CarousalCard
          image={edge.featuredImage?.node?.mediaItemUrl}
          title={edge.title}
          description={edge.acf.description}
          author={edge.author.node.name}
          category={edge.acf.category}
          publishDate={edge.date}
          readTime={edge.readingTime}
          link={edge.slug}
        />
      }
      <div className="dots">
        {filterSelected.map((edge, index) => {
          return <div className={`dot ` + (itemNumber === index && 'dot-selected')}
            onClick={() => setItemNumber(index)}
          ></div>;
        })}
      </div>
    </div>
  );
};

export default Carousal;
